import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Field, reduxForm, reset } from 'redux-form';
import {
  H1, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput, CheckboxInput } from 'components/inputs';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { AppToaster } from 'components/Toaster';

import { getUser, saveUser } from 'actions/user';
import { getOrganization } from 'actions/organization';
import { getRoles } from 'actions/role';

import RoleSelect from './input';

class User extends Component {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(getUser(match.params.id));
  }

  componentDidUpdate(prevProps) {
    const { user, dispatch } = this.props;
    const prevOrgId = (prevProps.user.data || {}).organization_id;
    const orgId = (user.data || {}).organization_id;
    if (orgId !== prevOrgId) {
      dispatch(getOrganization(orgId));
      dispatch(getRoles(orgId));
    }
  }

  @autobind
  handleSave(values) {
    const { dispatch, match } = this.props;
    return dispatch(saveUser(match.params.id, {
      name: values.name,
      email: values.email,
      disabled: values.disabled,
      password: values.password ? values.password : undefined,
      phone_number: values.phone_number,
      role_id: values.role ? values.role.role_id : undefined,
    })).then(() => {
      dispatch(reset('user'));
      AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {values.name}
            </strong>
            : User saved
          </span>
        ),
        intent: 'success',
      });
      return dispatch(getUser(match.params.id));
    });
  }

  render() {
    const {
      user, submitting, handleSubmit, match, organization,
    } = this.props;
    const userId = parseInt(match.params.id, 10);
    const org = (organization.data
      && (user.data || {}).organization_id === (organization.data || {}).id)
      ? organization.data : {};

    return (
      <div className="container">
        <div className={classNames('columns', { 'bp3-skeleton': user.pending && (user.data || {}).id !== userId })}>
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              {(user.data || {}).name}
              <div className="bp3-text-small bp3-text-muted">
                Registered:&nbsp;
                {DateTime.fromISO((user.data || {}).created).toLocaleString(DateTime.DATETIME_MED)}
                &nbsp;|
                Organization:&nbsp;
                <Link to={`/organizations/${(user.data || {}).organization_id}`}>{org.name || (user.data || {}).organization_id}</Link>
              </div>
            </H1>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="email"
                  label="Email Address"
                  type="text"
                />
              </div>
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="name"
                  label="Name"
                  type="text"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="phone_number"
                  label="Phone Number"
                  type="text"
                />
              </div>
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="password"
                  label="Password"
                  type="password"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Field
                  component={CheckboxInput}
                  type="checkbox"
                  id="disable"
                  name="disabled"
                  label="Disabled"
                />
              </div>
              <div className="column">
                <Field
                  component={RoleSelect}
                  name="role"
                />
              </div>
            </div>
            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

User.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  organization: PropTypes.object,
};

export default connect((state, props) => ({
  user: state.user,
  organization: state.organization,
  initialValues: state.user.data && state.user.data.id === parseInt(props.match.params.id, 10) ? {
    name: state.user.data.name,
    email: state.user.data.email,
    phone_number: state.user.data.phone_number || '',
    disabled: state.user.data.disabled,
    role: state.user.data.role,
  } : undefined,
}))(reduxForm({
  form: 'user',
  enableReinitialize: true,
})(User));
