import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  H1, Button,
} from '@blueprintjs/core';
import { autobind } from 'core-decorators';
import { TextInput, CheckboxInput, SelectInput } from 'components/inputs';
import { push } from 'connected-react-router';
import { AppToaster } from 'components/Toaster';

import { getOrganizations } from 'actions/organization';
import { createUser } from 'actions/user';

const formSelector = formValueSelector('create-user');

class User extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getOrganizations());
  }

  @autobind
  handleSave(values) {
    const { dispatch } = this.props;
    return dispatch(createUser({
      name: values.name,
      email: values.email,
      password: values.password,
      organization_id: values.organization.id,
      requires_email_verification: values.requires_email_verification,
      disabled: values.disabled,
    })).then(action => dispatch(push(`/users/${action.payload.data.content.id}`))
      || AppToaster.show({
        icon: 'tick',
        message: (
          <span>
            <strong>
              {action.payload.data.content.name}
            </strong>
            : User created
          </span>
        ),
        intent: 'success',
      }));
  }

  render() {
    const {
      submitting, handleSubmit, organizations, selectedOrg,
    } = this.props;
    const orgs = (organizations.data || []).map(x => ({ id: x.id, name: x.name }));
    const adminWarning = 'This organization is an administrative organization. This user will have access to admin functionality.';

    return (
      <div className="container">
        <div className="columns">
          <form onSubmit={handleSubmit(this.handleSave)} className="column" autoComplete="off">
            <H1>
              Create User
            </H1>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="email"
                  label="Email Address"
                  type="email"
                />
              </div>
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="name"
                  label="Name"
                  type="text"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Field
                  component={TextInput}
                  placeholder=""
                  name="password"
                  label="Password"
                  type="password"
                />
              </div>
              <div className="column">
                <Field
                  component={SelectInput}
                  name="organization"
                  label="Organization"
                  items={orgs}
                  help={selectedOrg === 1 ? adminWarning : undefined}
                  helpIntent={selectedOrg === 1 ? 'warning' : undefined}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-half">
                <Field
                  component={CheckboxInput}
                  type="checkbox"
                  id="disable"
                  name="disabled"
                  label="Disabled"
                />
                <Field
                  component={CheckboxInput}
                  type="checkbox"
                  id="requires_email_verification"
                  name="requires_email_verification"
                  label="Require Email Verification"
                />
              </div>
            </div>

            <div className="has-text-right">
              <Button intent="primary" loading={submitting} disabled={submitting} type="submit" icon="tick">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

User.propTypes = {
  dispatch: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  selectedOrg: PropTypes.number,
  organizations: PropTypes.object,
};

export default connect(state => ({
  selectedOrg: (formSelector(state, 'organization') || {}).id,
  organizations: state.organizations,
}))(reduxForm({
  form: 'create-user',
  validate: (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'This field is required.';
    }
    if (!values.name) {
      errors.name = 'This field is required.';
    }
    if (!values.password) {
      errors.password = 'This field is required.';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be longer than 8 characters';
    }
    if (!values.organization || !values.organization.id) {
      errors.organization = 'This field is required.';
    }
    return errors;
  },
})(User));
