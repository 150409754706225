export const LOGIN = 'admin/LOGIN';
export const LOGOUT = 'admin/LOGOUT';

export const GET_ADMIN_DASHBOARD = 'admin/GET_ADMIN_DASHBOARD';

export const GET_USER_PROFILE = 'admin/GET_USER_PROFILE';
export const GET_USER_ORGANIZATION = 'admin/GET_USER_ORGANIZATION';

export const GET_ORGANIZATIONS = 'admin/GET_ORGANIZATIONS';
export const GET_AUDIT_ORGANIZATIONS = 'admin/GET_AUDIT_ORGANIZATIONS';
export const GET_ORGANIZATION = 'admin/GET_ORGANIZATION';
export const CREATE_ORGANIZATION = 'admin/CREATE_ORGANIZATION';
export const SAVE_ORGANIZATION = 'admin/SAVE_ORGANIZATION';
export const GET_ORGANIZATION_DEVICES = 'admin/GET_ORGANIZATION_DEVICES';

export const GET_ROLES = 'admin/GET_ROLES';
export const GET_ROLE = 'admin/GET_ROLE';
export const CREATE_ROLE = 'admin/CREATE_ROLE';
export const SAVE_ROLE = 'admin/SAVE_ROLE';
export const DELETE_ROLE = 'admin/DELETE_ROLE';
export const GET_PERMISSIONS = 'admin/GET_PERMISSIONS';
export const SAVE_PERMISSIONS = 'admin/SAVE_PERMISSIONS';
export const GET_RESOURCES = 'admin/role/GET_RESOURCES';
export const SAVE_RESOURCES = 'admin/role/SAVE_RESOURCES';

export const GET_USERS = 'admin/GET_USERS';
export const GET_USER = 'admin/GET_USER';
export const CREATE_USER = 'admin/CREATE_USER';
export const SAVE_USER = 'admin/SAVE_USER';

export const GET_AUDITS = 'admin/GET_AUDITS';
export const GET_AUDIT = 'admin/GET_AUDIT';
export const CREATE_AUDIT = 'admin/CREATE_AUDIT';
export const GET_AUDIT_SUMMARY = 'admin/GET_AUDIT_SUMMARY';
export const GET_AUDIT_OVERVIEW = 'admin/GET_AUDIT_OVERVIEW';
export const DELETE_AUDIT = 'admin/DELETE_AUDIT';
export const UPDATE_GROUND_TRUTH = 'admin/UPDATE_GROUND_TRUTH';
export const GET_SIGNED_URL = 'admin/GET_SIGNED_URL';

export const GET_REID_TASKS = 'admin/GET_REID_TASKS';
export const GET_REID_TASK = 'admin/GET_REID_TASK';
export const CREATE_REID_TASK = 'admin/CREATE_REID_TASK';
export const CREATE_REID_MERGE_TASK = 'admin/CREATE_REID_MERGE_TASK';
export const POST_REID_MERGE_GROUND_TRUTH = 'admin/POST_REID_MERGE_GROUND_TRUTH';
export const REID_MERGE_OUTPUT = 'admin/REID_MERGE_OUTPUT';
export const EXTRACT_MERGE_TASK = 'admin/EXTRACT_MERGE_TASK';
export const EXTRACT_TASK = 'admin/EXTRACT_TASK';
export const GET_EXTRACT_TASK = 'admin/GET_EXTRACT_TASK';
export const REID_OUTPUT = 'admin/REID_OUTPUT';
export const TRACK_OUTPUT = 'admin/TRACK_OUTPUT';
export const PATCH_TRACK_OUTPUT = 'admin/PATCH_TRACK_OUTPUT';
export const GET_REID_SUMMARY = 'admin/GET_REID_SUMMARY';
export const GET_REID_OVERVIEW = 'admin/GET_REID_OVERVIEW';
export const DELETE_REID_TASK = 'admin/DELETE_RIED_TASK';
export const UPDATE_TRACK_GROUND_TRUTH = 'admin/UPDATE_REID_GROUND_TRUTH';
export const POST_TRACK_GROUND_TRUTH = 'admin/POST_REID_GROUND_TRUTH';
export const TRACK_GROUND_TRUTH = 'admin/TRACK_GROUND_TRUTH';
export const PATCH_REID_TASK = 'admin/PATCH_REID_TASK';
export const REID_WAIT_TIME = 'admin/REID_WAIT_TIME';
export const DELETE_REID_OUTPUT = 'admin/DELETE_REID_OUTPUT';
export const DELETE_REID_MERGE_OUTPUT = 'admin/DELETE_REID_MERGE_OUTPUT';
export const GET_REID_CLIP_PATCHES = 'admin/GET_REID_CLIP_PATCHES';
export const GET_REID_TASK_CLIPS = 'admin/GET_REID_TASK_CLIPS';
export const GET_REID_TASK_GROUPS = 'admin/GET_REID_TASK_GROUPS';
export const POST_REID_TASK_GROUP = 'admin/POST_REID_TASK_GROUP';
export const DELETE_REID_TASK_GROUP = 'admin/DELETE_REID_TASK_GROUP';
export const EXTRACT_ALL_PATCHES = 'admin/EXTRACT_ALL_PATCHES';
export const CREATE_REID_AUDIT_TASK = 'admin/CREATE_REID_AUDIT_TASK';
export const GET_REID_AUDIT_TASK = 'admin/GET_REID_AUDIT_TASK';
export const GET_REID_AUDIT_TASKS = 'admin/GET_REID_AUDIT_TASKS';
export const PATCH_REID_AUDIT_TASK = 'admin/PATCH_REID_AUDIT_TASK';
export const REID_AUDIT_SUMMARY = 'admin/REID_AUDIT_SUMMARY';
export const REID_AUDIT_MATRIX = 'admin/REID_AUDIT_MATRIX';
export const REID_AUDIT_TRACKS = 'admin/REID_AUDIT_TRACKS';
export const EXTRACT_REID_AUDIT_TASK = 'admin/EXTRACT_REID_AUDIT_TASK';
export const DELETE_REID_AUDIT_OUTPUT = 'admin/DELETE_REID_AUDIT_OUTPUT';
export const COMPARE_REID_AUDIT_TASKS = 'admin/COMPARE_REID_AUDIT_TASKS';


export const GET_LOCATIONS = 'admin/GET_LOCATIONS';
export const GET_LOCATION = 'admin/GET_LOCATION';

export const GET_DEVICES = 'admin/GET_DEVICES';
export const GET_DEVICE = 'admin/GET_DEVICE';
export const SAVE_DEVICE = 'admin/SAVE_DEVICE';
export const REBOOT_DEVICE = 'admin/REBOOT_DEVICE';
export const ASSIGN_DEVICE = 'admin/ASSIGN_DEVICE';
export const UNASSIGN_DEVICE = 'admin/UNASSIGN_DEVICE';
export const BOSUN_METRICS_DEVICE = 'admin/BOSTIN_METRICS_DEVICE';
export const IAP_METRICS_DEVICE = 'admin/IAP_METRICS_DEVICE';
export const BEACON_METRICS_DEVICE = 'admin/BEACON_METRICS_DEVICE';
export const DEVICE_MAC = 'admin/DEVICE_MAC';

export const GET_NVR = 'admin/GET_NVR';
export const GET_NVRS = 'admin/GET_NVRS';
export const GET_NVR_VERSIONS = 'admin/GET_NVR_VERSIONS';
export const SAVE_NVR = 'admin/SAVE_NVR';
export const NVR_METRICS = 'admin/NVR_METRICS';
export const GET_NVR_RELEASES = 'admin/GET_NVR_RELEASES';
export const GET_NVR_RELEASE = 'admin/GET_NVR_RELEASE';
export const GET_NVR_SERVICES = 'admin/GET_NVR_SERVICES';
export const CREATE_NVR_RELEASE = 'admin/CREATE_NVR_RELEASE';
export const EDIT_NVR_RELEASE = 'admin/EDIT_NVR_RELEASE';

export const GET_SITE = 'admin/GET_SITE';
export const GET_SITE_DEVICES = 'admin/GET_SITE_DEVICES';

export const GET_IAP_VERSIONS = 'admin/GET_IAP_VERSIONS';
export const GET_SITES = 'admin/GET_SITES';
export const GET_AUDIT_SITES = 'admin/GET_AUDIT_SITES';
export const GET_AUDIT_SITE_DEVICES = 'admin/GET_AUDIT_SITE_DEVICES';

export const GET_ZONES = 'admin/GET_ZONES';
export const GET_ZONE = 'admin/GET_ZONE';
export const GET_AUDIT_LOCATIONS = 'admin/GET_AUDIT_LOCATIONS';

export const SAVE_SITE = 'admin/SAVE_SITE';
export const SAVE_ZONE = 'admin/SAVE_ZONE';

export const GET_SITE_OP_HOURS = 'admin/GET_SITE_OP_HOURS';
export const SAVE_SITE_OP_HOURS = 'admin/SAVE_SITE_OP_HOURS';

export const POST_GROUNDTRUTH = 'admin/POST_GROUNDTRUTH';
export const GET_GROUNDTRUTH = 'admin/GET_GROUNDTRUTH';

export const SAVE_LOCATION = 'admin/SAVE_LOCATION';

export const GET_PIPELINES = 'admin/GET_PIPELINES';
export const EDIT_PIPELINE = 'admin/EDIT_PIPELINE';
export const DELETE_PIPELINE = 'admin/DELETE_PIPELINE';

export const GET_ZONE_DEVICES = 'admin/GET_ZONE_DEVICES';
export const GET_ALL_DEVICES = 'admin/GET_ALL_DEVICES';

export const GET_IAP_RELEASES = 'admin/GET_IAP_RELEASES';
export const GET_IAP_RELEASE = 'admin/GET_IAP_RELEASE';
export const GET_IAP_SERVICES = 'admin/GET_IAP_SERVICES';
export const CREATE_IAP_RELEASE = 'admin/CREATE_IAP_RELEASE';
export const EDIT_IAP_RELEASE = 'admin/EDIT_IAP_RELEASE';
export const GET_VISION_PARAMETERS = 'admin/GET_VISION_PARAMETERS';
export const PATCH_VISION_PARAMETERS = 'admin/PATCH_VISION_PARAMETERS';
export const FETCH_IMAGE = 'admin/FETCH_IMAGE';
export const REBOOT_V3 = 'admin/REBOOT_V3';
export const GET_INS_OUTS = 'admin/GET_INS_OUTS';
export const GET_LINES_INS_OUTS = 'admin/GET_LINES_INS_OUTS';
export const QUERY_DATE_FMT = 'YYYY-MM-DDTHH:mm:ss';
export const GET_FPS = 'admin/GET_FPS';
export const GET_CAMERA_RESOLUTION = 'admin/GET_CAMERA_RESOLUTION';
export const ADD_CAMERA = 'admin/ADD_CAMERA';
export const PATCH_ORG_DEVICES = 'admin/PATCH_ORG_DEVICES';

export const POST_WHITELIST = 'admin/POST_WHITELIST';
export const DELETE_WHITELIST = 'admin/DELETE_WHITELIST';
export const GET_LOCATION_WHITELIST = 'admin/GET_LOCATION_WHITELIST';

export const GET_IAP_CAMERAS = 'admin/GET_IAP_CAMERAS';
export const POST_IAP_CAMERA = 'admin/POST_IAP_CAMERA';
export const PATCH_IAP_CAMERA = 'admin/PATCH_IAP_CAMERA';
export const DELETE_IAP_CAMERA = 'admin/DELETE_IAP_CAMERA';
export const ADD_ZONE_DEVICE = 'admin/ADD_ZONE_DEVICE';
export const RESET_OCCUPANCY = 'admin/RESET_OCCUPANCY';

export const ENABLE_TWO_FACTOR = 'app/ENABLE_TWO_FACTOR';
export const DISABLE_TWO_FACTOR = 'app/DISABLE_TWO_FACTOR';
export const VERIFY_TWO_FACTOR = 'app/VERIFY_TWO_FACTOR';
export const TWO_FACTOR_LOGIN = 'app/TWO_FACTOR_LOGIN';
export const TWO_FACTOR_RESEND = 'app/TWO_FACTOR_RESEND';

export const GET_HOMOGRAPHY = 'admin/GET_HOMOGRAPHY';
export const POST_HOMOGRAPHY = 'admin/POST_HOMOGRAPHY';
export const VALIDATE_HOMOGRAPHY = 'admin/VALIDATE_HOMOGRAPHY';
export const DELETE_HOMOGRAPHY = 'admin/DELETE_HOMOGRAPHY';

export const PATCH_ZONE_CONFIGS = 'admin/PATCH_ZONE_CONFIGS';
export const GET_ML_MODELS = 'admin/GET_ML_MODELS';
export const PATCH_AUDIT = 'admin/PATCH_AUDIT';

export const SCAN_AVAILABLE_CAMERAS = 'admin/SCAN_AVAILABLE_CAMERAS';
export const GET_CAMERA_UPLOADS = 'admin/GET_CAMERA_UPLOADS';
export const START_CAMERA_RECORDING = 'admin/START_CAMERA_RECORDING';
export const STOP_CAMERA_RECORDING = 'admin/STOP_CAMERA_RECORDING';
export const UPLOAD_RECORDING = 'admin/UPLOAD_RECORDING';
export const RECURRING_UPLOAD_RECORDING = 'admin/RECURRING_UPLOAD_RECORDING';
export const VIEW_EXISTING_UPLOADS = 'admin/VIEW_EXISTING_UPLOADS';
export const DELETE_EXISTING_UPLOAD = 'admin/DELETE_EXISTING_UPLOAD';
export const COMPARE_AUDIT = 'admin/COMPARE_AUDIT';
export const COMPARE_AUDIT_SUMMARY = 'admin/COMPARE_AUDIT_SUMMARY';

export const UPDATE_DATERANGE = 'app/UPDATE_DATERANGE';
export const UPDATE_AUDIT_TAGS = 'app/UPDATE_AUDIT_TAGS';

export const UPDATE_ZONE_TAB_PERMISSIONS = 'app/UPDATE_ZONE_TAB_PERMISSIONS';

export const GET_AUDIT_CLIPS = 'app/GET_AUDIT_CLIPS';
export const GET_AUDIT_CLIP = 'app/GET_AUDIT_CLIP';
export const CREATE_TAG = 'app/CREATE_TAG';
export const DELETE_TAG = 'app/DELETE_TAG';
export const GET_CLIP_TAGS = 'app/GET_CLIP_TAGS';

export const GET_CAMERA_MODELS = 'admin/GET_CAMERA_MODELS';
export const UPDATE_ML_MODEL = 'admin/UPDATE_MODEL';
export const GET_ML_MODEL = 'admin/GET_ML_MODEL';
export const CREATE_ML_MODEL = 'admin/CREATE_ML_MODEL';
export const DELETE_ML_MODEL = 'admin/DELETE_ML_MODEL';

export const GET_DASHES = 'admin/GET_DASHES';
export const ADD_CARDS = 'admin/ADD_CARDS';
export const GET_DASH = 'admin/GET_DASH';
export const PATCH_CARDS_DASH = 'admin/PATCH_CARDS_DASH';

export const GET_ZONE_OCCUPANCY = 'admin/GET_ZONE_OCCUPANCY';
export const ZONE_QUERY = 'admin/ZONE_QUERY';
export const DELETE_DASHBOARD = 'admin/DELETE_DASHBOARD';

export const GET_PEPLINK_DEVICES = 'admin/GET_PEPLINK_DEVICES';
export const GET_SIM_POOLS = 'admin/GET_SIM_POOLS';
export const ADD_PEPLINK = 'admin/ADD_PEPLINK';
export const REBOOT_PEPLINK = 'admin/REBOOT_PEPLINK';
export const GET_PEPLINK = 'admin/GET_PEPLINK';
export const GET_PEPLINK_BANDWIDTH_USAGE = 'admin/GET_PEPLINK_BANDWIDTH_USAGE';
export const GET_PEPLINK_CLIENTS = 'admin/GET_PEPLINK_CLIENTS';
export const GET_PEPLINK_LOGS = 'admin/GET_PEPLINK_LOGS';

export const DASH_QUERY = 'admin/DASH_QUERY';
export const GET_DASH_ZONE_DEVICES = 'admin/GET_DASH_ZONE_DEVICES';
export const GET_CLIPS_BY_TIME = 'admin/GET_CLIPS_BY_TIME';

export const CAMERA_STATS_INS = 'admin/CAMERA_STATS_INS';
export const CAMERA_STATS_FPS = 'admin/CAMERA_STATS_FPS';
export const CAMERA_LINE_STATS = 'admin/CAMERA_LINE_STATS';
export const CAMERA_STATS_RESOLUTION = 'admin/CAMERA_STATS_RESOLUTION';

export const GET_TRAINER_RESOURCES = 'admin/GET_TRAINER_RESOURCES';
export const GET_TRAINING_DATASETS = 'admin/GET_TRAINING_DATASETS';
export const GET_ALL_TRAINING_TASKS = 'admin/GET_ALL_TRAINING_TASKS';
export const GET_TRAINING_STATUS = 'admin/GET_TRAINING_STATUS';
export const CREATE_TRAINING_TASK = 'admin/CREATE_TRAINING_TASK';
export const TERMINATE_TRAINING = 'admin/TERMINATE_TRAINING';

export const GET_ASSIGNED_CLIPS = 'admin/GET_ASSIGNED_CLIPS';
export const GET_ASSIGNED_CLIPS_ORG = 'admin/GET_ASSIGNED_CLIPS_ORG';
export const GET_CLIPS_SUMMARY = 'admin/GET_CLIPS_SUMMARY';

export const GET_DISPLAYS = 'admin/GET_DISPLAYS';
export const SET_DISPLAY_HOURS = 'admin/SET_DISPLAY_HOURS';
export const GET_DISPLAY_HOURS = 'admin/GET_DISPLAY_HOURS';

export const GET_ALERTS = 'admin/GET_ALERTS';
export const PATCH_ALERT = 'admin/PATCH_ALERT';
export const DELETE_ALERT = 'admin/DELETE_ALERT';
export const CREATE_ALERT = 'admin/CREATE_ALERT';
export const GET_ALERT = 'admin/GET_ALERT';
export const GET_ACTIVE_ALERTS = 'admin/GET_ACTIVE_ALERTS';
export const PATCH_ALERT_STATUS = 'admin/PATCH_ALERT_STATUS';

export const GET_CAMERA_COUNTING_LINES = 'admin/GET_CAMERA_COUNTING_LINES';
export const GET_COUNTING_LINES_BY_CAMERA_LINE_ID = 'admin/GET_COUNTING_LINES_BY_CAMERA_LINE_ID';
export const CREATE_COUNTING_LINE = 'admin/CREATE_COUNTING_LINE';
export const UPDATE_COUNTING_LINE_BY_LINE_ID = 'admin/UPDATE_COUNTING_LINE_BY_LINE_ID';
export const DELETE_COUNTING_LINE_BY_LINE_ID = 'admin/DELETE_COUNTING_LINE_BY_LINE_ID';
export const GET_RAW_AUDIT_CLIP = 'admin/GET_RAW_AUDIT_CLIP';
export const ADD_WAIT_TIME = 'admin/ADD_WAIT_TIME';
export const GET_WAIT_TIMES = 'admin/GET_WAIT_TIMES';

export const GET_CLIPS_BY_CAMERA = 'admin/GET_CLIPS_BY_CAMERA';
