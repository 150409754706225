import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  H1, FormGroup, InputGroup, Icon, Spinner, Button, NonIdealState, MenuItem,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { DateTime } from 'luxon';

import { getUsers } from 'actions/user';
import { getOrganizations } from 'actions/organization';

const filterOrg = (query, org) => org.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
const renderTag = org => org.name;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      status: '',
      orgs: [],
    };
    this.doRefresh = _.debounce(this.refresh, 100);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUsers());
    dispatch(getOrganizations());
  }

  @autobind
  onOrgSelect(org) {
    const { orgs } = this.state;
    if (_.some(orgs, x => x.id === org.id)) {
      this.setState({ orgs: orgs.filter(x => x.id !== org.id) }, this.doRefresh);
    } else {
      this.setState({ orgs: [...orgs, org] }, this.doRefresh);
    }
  }

  @autobind
  onOrgClear() {
    this.setState({ orgs: [] }, this.doRefresh);
  }

  @autobind
  onOrgRemove(tag, index) {
    const { orgs } = this.state;
    this.setState({ orgs: orgs.filter((x, i) => i !== index) }, this.doRefresh);
  }

  @autobind
  refresh() {
    const { dispatch } = this.props;
    const { query, status, orgs } = this.state;
    dispatch(getUsers(query, status, orgs ? orgs.map(o => o.id) : undefined));
  }

  @autobind
  handleQuery(e) {
    this.setState({ query: e.target.value }, this.doRefresh);
  }

  @autobind
  handleStatus(e) {
    this.setState({ status: e.target.value }, this.doRefresh);
  }

  @autobind
  renderUser(user, i) {
    const { organizations } = this.props;
    const org = _.find(organizations.data || [], x => x.id === user.organization_id) || {};
    return (
      <tr key={i}>
        <td><Link to={`/users/${user.id}`}>{user.name || user.id}</Link></td>
        <td>{user.email}</td>
        <td><Link to={`/organizations/${user.organization_id}`}>{org.name || user.organization_id}</Link></td>
        <td>{DateTime.fromISO(user.created).toLocaleString(DateTime.DATETIME_MED)}</td>
        <td>{user.role && user.role.role_name}</td>
        <td className="has-text-centered"><Icon icon="symbol-circle" intent={user.disabled ? 'danger' : 'success'} /></td>
      </tr>
    );
  }

  @autobind
  renderOrg(org, { handleClick, modifiers }) {
    const { orgs } = this.state;
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={`${org.slug.slice(0, 5)}`}
        key={org.id}
        icon={_.some(orgs, x => x.id === org.id) ? 'tick' : 'blank'}
        onClick={handleClick}
        text={org.name}
      />
    );
  }

  render() {
    const { query, status, orgs } = this.state;
    const { users, organizations } = this.props;
    const spinner = users.pending ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined;
    const clearOrgButton = orgs.length > 0 ? <Button icon="cross" minimal onClick={this.onOrgClear} /> : null;

    return (
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column p-b-none">
                <H1>
                  Users
                  <Link
                    className="bp3-button bp3-large is-pulled-right bp3-intent-primary"
                    to="/users/create"
                  >
                    <Icon icon="add" />
                  </Link>
                </H1>
              </div>
            </div>
            <div className="columns">
              <div className="column p-t-none">
                <FormGroup>
                  <InputGroup value={query} onChange={this.handleQuery} leftIcon="search" large placeholder="" rightElement={spinner} />
                </FormGroup>
                <div className="columns">
                  <div className="column has-text-right">
                    <FormGroup
                      label="Organizations"
                      labelFor="organizations"
                      className="inline-flex m-r-md"
                    >
                      <MultiSelect
                        resetOnSelect
                        items={organizations.data || []}
                        itemPredicate={filterOrg}
                        itemRenderer={this.renderOrg}
                        tagRenderer={renderTag}
                        noResults={<MenuItem disabled text="No results." />}
                        selectedItems={orgs}
                        onItemSelect={this.onOrgSelect}
                        popoverProps={{ minimal: true }}
                        tagInputProps={{
                          onRemove: this.onOrgRemove,
                          rightElement: clearOrgButton,
                          placeholder: 'All Organizations',
                          className: 'min-width-tag-input',
                        }}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Status"
                      labelFor="user-status"
                      className="inline-flex"
                    >
                      <div className="bp3-select">
                        <select id="user-status" value={status} onChange={this.handleStatus}>
                          <option value="">--</option>
                          <option value="true">Active</option>
                          <option value="false">Disabled</option>
                        </select>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {!((users.data || []).length) && <NonIdealState icon="search" title="No results." />}
            {!!((users.data || []).length) && (
              <table className={classNames('bp3-html-table bp3-html-table-striped bp3-interactive', { 'bp3-skeleton': false })}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Organization</th>
                    <th>Registered</th>
                    <th>Role</th>
                    <th className="has-text-centered">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {(users.data || []).map(this.renderUser)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  dispatch: PropTypes.func,
  users: PropTypes.object,
  organizations: PropTypes.object,
};

export default connect(state => ({
  users: state.users,
  organizations: state.organizations,
}))(Users);
