import qs from 'query-string';
import {
  LOGIN, LOGOUT, GET_USER_PROFILE, GET_USERS, GET_USER, CREATE_USER, SAVE_USER,
  ENABLE_TWO_FACTOR, DISABLE_TWO_FACTOR, VERIFY_TWO_FACTOR,
  TWO_FACTOR_LOGIN, TWO_FACTOR_RESEND,
} from '../constants';

export const login = (username, password, rememberMe) => ({
  type: LOGIN,
  payload: {
    request: {
      method: 'post',
      url: '/v1/oauth/internal/login',
      data: {
        grant_type: 'password',
        username,
        password,
        remember_me: !!rememberMe,
        state: {
          r: !!rememberMe,
        },
        insecure: process.env.NODE_ENV !== 'production' || undefined,
        organization_id: undefined,
      },
    },
  },
});

export const logout = () => ({
  type: LOGOUT,
  payload: {},
});

export const getCurrentUser = token => ({
  type: GET_USER_PROFILE,
  payload: {
    request: {
      method: 'get',
      url: '/v1/users/me',
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  },
});

export const getUsers = (query, status, organization, from, limit) => ({
  type: GET_USERS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/users?${qs.stringify({
        query, status, from, limit, organization,
      })}`,
    },
  },
});

export const createUser = data => ({
  type: CREATE_USER,
  payload: {
    request: {
      method: 'post',
      data,
      url: '/v1/admin/users/',
    },
  },
});

export const getUser = id => ({
  type: GET_USER,
  payload: {
    request: {
      method: 'get',
      url: `/v1/admin/users/${id}`,
    },
  },
});

export const saveUser = (id, data) => ({
  type: SAVE_USER,
  payload: {
    request: {
      method: 'patch',
      data,
      url: `/v1/admin/users/${id}`,
    },
  },
});

export const enableTwoFactor = (token, data) => ({
  type: ENABLE_TWO_FACTOR,
  payload: {
    request: {
      method: 'post',
      url: '/v1/users/twofactor',
      data,
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  },
});

export const disableTwoFactor = () => ({
  type: DISABLE_TWO_FACTOR,
  payload: {
    request: {
      method: 'delete',
      url: '/v1/users/twofactor',
    },
  },
});

export const verifyTwoFactor = (userId, code) => ({
  type: VERIFY_TWO_FACTOR,
  payload: {
    request: {
      method: 'post',
      url: `/v1/users/${userId}/twofactor/verify/${code}`,
    },
  },
});

export const twoFactorLogin = (code, token) => ({
  type: TWO_FACTOR_LOGIN,
  payload: {
    request: {
      method: 'post',
      url: '/v1/twofactor',
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      data: { code },
    },
  },
});

export const resendTwoFactor = token => ({
  type: TWO_FACTOR_RESEND,
  payload: {
    request: {
      method: 'post',
      url: '/v1/twofactor/resend',
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  },
});

export const getUsersNonAdmin = (query, status, organization, from, limit) => ({
  type: GET_USERS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/users?${qs.stringify({
        query, status, from, limit, organization,
      })}`,
    },
  },
});
